//	Settings
var setting = {
	mediaQuery: {
		sizes: {
			small: 768,
			medium: 992,
			large: 1200
		},
		active: '',
		previous: ''
	},
	vendor: {
		flexslider: {
			directionNav: false
		}
	}
};

//	Variables
var $core = {
	win: $(window),
	doc: $(document),
	body: $('body')
};

var $site = {
	nav: {
		primary: $core.body.find('nav.primary'),
	},
	main: $core.body.find('main'),
	modals: {
	}
};

var site = {
	resize: {
		timer: false,
		before: {},
		after: {}
	},
	mooseBan: false,
	width: $core.win.width(),
	height: $core.win.height(),
	scroll: {
		x: 0,
		y: 0
	}
};

$core.body
	.on('hidden.bs.modal', '#blankModal', function () {

		var $this = $(this);

		$this
			.removeData('bs.modal')
			.find('.modal-content')
			.empty();
	})
	.on('loaded.bs.modal', '.modal', function (data) {
		//
	});

//if (Modernizr.touch) {
//	FastClick.attach(document.body);
//}

//	SVG Replacement
//if (!Modernizr.svg) {
//	$('img[src$=".svg"]').each(function () {
//		var $img = $(this);
//		$img.attr('src', $img.attr('src').replace(/svg$/, 'png'));
//	});
//}

$.ajaxSetup({
	headers: {
		'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	}
});

/*--------------------------------
 Functions
 --------------------------------*/

/**
 *  Updates site metrics and calls any necessary
 *  functions before and then after a browser resize.
 */
var beforeResize = function () {

	$.each(site.resize.before, function () {

		this();

	});

};

var afterResize = function () {

	site.width = $core.win.width();
	site.height = $core.win.height();

	$.each(site.resize.after, function () {

		this();

	});

};

/*--------------------------------
 Indirect events
 --------------------------------*/

$core.win.on({

	resize: function () {

		if (!site.resize.timer)
			beforeResize();

		clearTimeout(site.resize.timer);

		site.resize.timer = setTimeout(function () {

			afterResize();

			site.resize.timer = false;

		}, 200);

	},
	scroll: function () {

		site.scroll.y = document.documentElement.scrollTop;
		site.scroll.y = site.scroll.y === 0 ? document.body.scrollTop : site.scroll.y;

		site.scroll.x = document.documentElement.scrollLeft;
		site.scroll.x = site.scroll.x === 0 ? document.body.scrollLeft : site.scroll.x;

	}

});

// External Links
$core.body.on('click', 'a.external, a:external', function (e) {

	if (e.which != 2) {

		window.open($(this).attr('href'));
		e.preventDefault();

	}

});

//
//	jQuery - Ready
//

$(function () {

	$core.body
		.find('select')
		.each(function() {

			var $select = $(this);

			var hasOptions = $select.children('option').length > 1;

			$select.attr('disabled', !hasOptions);

		});

	var $form = $core.body.find('form');

	var vm = new Vue({
		el: 'main',
		ready: function() {
			//
		},
		data: {
			next_visit: '—',
			last_visit: '—',
			// area: areasArray,
			// maintenanceType: [],
			// location: [],
			// locationDisabled: this.$data.location.length,
			// street: []
		},
		methods: {
			areaChanged: function() {

				var data = {
					area_id: $form.find('[name="area_id"]').val()
				};

				window.location = $form.attr('action') + '?' + $.param(data);

			},
			maintenanceTypeChanged: function() {

				var data = {
					area_id: $form.find('[name="area_id"]').val(),
					maintenance_type_id: $form.find('[name="maintenance_type_id"]').val()
				};

				window.location = $form.attr('action') + '?' + $.param(data);

			},
			locationChanged: function() {

				var data = {
					area_id: $form.find('[name="area_id"]').val(),
					maintenance_type_id: $form.find('[name="maintenance_type_id"]').val(),
					location_id: $form.find('[name="location_id"]').val()
				};

				window.location = $form.attr('action') + '?' + $.param(data);

			},
			streetChanged: function() {

				var data = {
					area_id: $form.find('[name="area_id"]').val(),
					maintenance_type_id: $form.find('[name="maintenance_type_id"]').val(),
					location_id: $form.find('[name="location_id"]').val(),
					street_id: $form.find('[name="street_id"]').val()
				};

				window.location = $form.attr('action') + '?' + $.param(data);

			}
		}
	});

	//$core.body
	//	.find('#area_id')
	//	.on('change', function(e){
	//
	//		var $field = $(this);
	//
	//		var data = {
	//			area_id: $field.val()
	//		};
	//
	//
	//
	//	});

});

//
//	Plugins
//

(function ($) {

})(jQuery);

/*--------------------------------

 External/internal links

 --------------------------------*/

jQuery.extend(jQuery.expr[':'], {
	external: function (obj, index, meta, stack) {

		return (obj.hostname != location.hostname) && /:\/\//.test($(obj).attr('href'));

	},
	internal: function (obj, index, meta, stack) {

		return (obj.hostname == location.hostname) || !/:\/\//.test($(obj).attr('href'));

	}
});

Object.size = function(obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};